import axios from "axios";
import { Toast } from "vant";
import store from "@/store/index.js";
import router from "@/router/index.js";
// import { loginOut } from '@/api/account.js';
// let apiUrl = 'https://server.gqyql820.top/api';

// let apiUrl = 'https://new.stocks.server.lsb200.com/api';
axios.defaults.withCredentials = false;

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "ba-user-token": store.state.token, // 用户token
      lang: store.state.lang,
      server: "true",
      ...config.headers,
    };
    config.baseURL = "https://" + store.state.apiDomain + "/api";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// http response 拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.status == 200) {
      if (res.data.code === 0) {
        Toast.fail(res.data.msg);
        // return res.data;
        return Promise.reject();
      } else if (res.data.code === 1) {
        if (res.data.msg !== "success") {
          Toast.success(res.data.msg);
        }
        return res.data;
      } else if ([302, 409].includes(res.data.code)) {
        Toast.fail(res.data.msg);
        localStorage.removeItem("stocks");
        router.push("/login");
      }
    } else if (res.status === 500 && res.data.code === 409) {
      Toast.fail(res.data.msg);
      localStorage.removeItem("stocks");
      router.push("/login");
    } else {
      Toast.fail("error");
    }
  },
  (error) => {
    // Toast.fail("error");
    // return error.response;
    return Promise.reject(error);
  }
);

export default axios;
