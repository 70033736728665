import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    persistedState({
      key: "stocks",
      // storage: window.sessionStorage
    }),
  ],
  state: {
    apiDomain: "",
    projectName: "Stocks",
    userInfoCount: 0, //监听到改变时,更新用户信息
    token: "",
    refreshToken: "",
    lang: "cn",
    userInfo: {
      avatar: "",
      email: "",
      money: 0,
      freeze_funds: 0,
    },
    symbol: "$",
    currency: "USD",
    onlineServiceUrl: "",
    loanCustomerServiceUrl: "",
    downloadUrl: {
      ios: "",
      android: "",
    },
  },
  getters: {
    userInfoCount: (state) => state.userInfoCount,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    lang: (state) => state.lang,
    userInfo: (state) => state.userInfo,
    symbol: (state) => state.symbol,
    currency: (state) => state.currency,
    onlineServiceUrl: (state) => state.onlineServiceUrl,
    loanCustomerServiceUrl: (state) => state.loanCustomerServiceUrl,
    downloadUrl: (state) => state.downloadUrl,
    projectName: (state) => state.projectName,
  },
  mutations: {
    setUserInfoCount(state) {
      state.userInfoCount++;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setSymbol(state, symbol) {
      state.symbol = symbol;
      state.currency = symbol === "$" ? "USD" : symbol === "¥" ? "CNY" : "HKD";
    },
    setOnlineService(state, url) {
      state.onlineServiceUrl = url;
    },
    setLoanCustomerService(state, url) {
      state.loanCustomerServiceUrl = url;
    },
    setDownloadUrl(state, obj) {
      state.downloadUrl = obj;
    },
    setProjectName(state, name) {
      state.projectName = name;
    },
  },
  actions: {
    setUserInfoCount(context) {
      context.commit("setUserInfoCount");
    },
    setToken(context, token) {
      context.commit("setToken", token);
    },
    setLang(context, lang) {
      context.commit("setLang", lang);
    },
    setRefreshToken(context, refreshToken) {
      context.commit("setRefreshToken", refreshToken);
    },
    setUserInfo(context, info) {
      context.commit("setUserInfo", info);
    },
    setSymbol(context, symbol) {
      context.commit("setSymbol", symbol);
    },
    setOnlineService(context, url) {
      context.commit("setOnlineService", url);
    },
    setLoanCustomerService(context, url) {
      context.commit("setLoanCustomerService", url);
    },
    setDownloadUrl(context, obj) {
      context.commit("setDownloadUrl", obj);
    },
    setProjectName(context, name) {
      context.commit("setProjectName", name);
    },
  },
});
